.media-list {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.media {
  padding: 1rem 1.5rem;
}

.button {
  display: inline-flex;
  align-self: center;
  margin-right: 0.75rem;
}
