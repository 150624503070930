.plan {
  margin: 0px 10%;

  .planHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 600px) {
    .planHeader {
      flex-direction: column-reverse;
    }
  }

  .planTitle {
    color: #E87722;
    font-family: "DIN Condensed";
    font-size: 40px;
    letter-spacing: 2.4px;
  }

  .orderOfManLogo {
    width: 105px;
    height: 105px;
  }

  .loading {
    width: 50px;
    height: 50px;
  }

  .planSubTitle {
    color: #333333;
    font-family: "Open",sans-serif;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0.06px;
  }

  h3 {
    font-family: "DIN Condensed";
    font-size: 30px;
    letter-spacing: 1.2px;
  }

  .myVisionText {
    white-space: pre-line;
  }

  .myVision {
    color: #E87722;
  }

  .calibration {
    color: #8A7F5B;
  }

  .connection {
    color: #96561F;
  }

  .condition {
    color: #195069;
  }

  .contribution {
    color: #5B531C;
  }

  .question {
    color: #333333;
  }

  .button{
    height: 50px;
    width: 280px;
    border-radius: 25px;
    background-color: #E87722;
    margin-top: 20px;
    color: #FFFFFF;
    font-family: "Open",sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .disclaimer {
    color: #333333;
    font-family: "Gotham",sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.09px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .block {
    margin: 40px 0px;
  }

  p {
    font-family: "Open",sans-serif;
    color: #333333;
  }

  hr {
    background-color: #979797;
    border: 0 none;
    color: #979797;
    height: 1px;
    margin: 35px 0px;
  }
}